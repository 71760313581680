.flex-center {
  display: flex;
  align-items: center;
}
.flex-row {
  display: flex;
  align-items: center;
  gap: var(--dj-size-spacing-sm) var(--dj-size-spacing-lg);
  flex-wrap: wrap;
}
.flex-group {
  display: flex;
  align-items: center;
  gap: var(--dj-size-spacing-xs);
  flex-wrap: wrap;
}
.flex-column {
  display: flex;
  flex-direction: column;
  gap: var(--dj-size-spacing-xl);
}
.hero-split {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* main view division */
.container {
  display: flex;
}
.side-area {
  padding: var(--dj-size-spacing-xl) var(--dj-size-spacing-2xl);
  width: 400px;
  flex-shrink: 0;
}
.main-area {
  padding: var(--dj-size-spacing-xl) var(--dj-size-spacing-2xl);
  flex-grow: 1;
}

/* table */
table {
  width: 100%;
  text-align: left;
}
thead {
  box-shadow: var(--dj-shadow-sm);
  background-color: var(--dj-color-card-background);
}
tr:nth-child(even) {
  background: var(--dj-color-card-background);
}
tr:nth-child(odd) {
  background: var(--dj-color-card-background);
}
td,
th {
  padding: var(--dj-size-spacing-sm) var(--dj-size-spacing-sm);
}

/* other */
.pointer {
  cursor: pointer;
}
